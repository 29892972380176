"use client";

import { RootState } from "@/app-core/src/lib/redux/store";
import { UIState } from "@/app-core/src/lib/redux/uiSlice";
import { cn } from "@/lib/utils";
import { VariantProps, cva } from "class-variance-authority";
import React, { HTMLAttributes, RefObject, forwardRef, useLayoutEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { PageContentContainer as CommonPageContentContainer, PageContentContainerProps } from "@/common/src/components/ui/PageContentContainer";

export const PageContentContainer = forwardRef<HTMLDivElement, PageContentContainerProps>(({ ...props }, ref) => {
  const UIState = useSelector((state: RootState) => state.ui) as UIState; // Access user state
  //console.log("UIState*****", UIState);
  return <CommonPageContentContainer {...props} UIState={UIState} ref={ref} />;
});

PageContentContainer.displayName = "PageContentContainer";
